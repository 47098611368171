import { mdiArrowCollapse, mdiArrowExpand } from '@mdi/js';
import Icon from '@mdi/react';
import React, { PureComponent, ReactNode } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { eventBus } from '../../context/Contilio360Context';
import { AssetUrl } from '../../enums/AssetUrl';
import { ConfigEvent } from '../../events/ConfigEvent';
import { Contilio360Event } from '../../events/Contilio360Event';
import { ConfigModel } from '../../models/ConfigModel';
import { ViewSyncModel } from '../../models/ViewSyncModel';
import './Floorplan.scss';

/**
 * Interactive floor-plan
 * @author  Neil Rackett
 */
export class Floorplan extends PureComponent<any, any> {
  protected config!: ConfigModel; // Injected
  protected viewSync!: ViewSyncModel; // Injected

  constructor(props: any) {
    super(props);
    eventBus.inject(this);
    this.state = {
      imgSrc: `${AssetUrl.S3_URI}/${this.config.floorplan.image}`,
      markerLeft: '0',
      markerBottom: '0',
      markerRotation: 0,
      maximized: false,
      scanLocation: 0,
      notesVisible: false,
    };
  }

  public componentDidMount(): void {
    this.config.addEventListener(ConfigEvent.SCAN_LOCATION_CHANGE, this.loadSync);
    this.viewSync.addEventListener(Contilio360Event.CHANGE, this.loadSync);
    this.loadSync();
  }

  public componentWillUnmount(): void {
    this.config.removeEventListener(ConfigEvent.SCAN_LOCATION_CHANGE, this.loadSync);
    this.viewSync.removeEventListener(Contilio360Event.CHANGE, this.loadSync);
    eventBus.uninject(this);
  }

  public render(): ReactNode {
    const { floorplan } = this.config;
    const classList = ['Floorplan'];

    if (this.state.maximized) {
      classList.push('maximized');
    }

    if (this.props.className) {
      classList.push(this.props.className);
    }

    return (
      <div className={classList.join(' ')}>
        <div>
          <Dropdown text='Floor 1'>
            <Dropdown.Menu>
              <Dropdown.Item text='Floor 1' />
              <Dropdown.Item text='Floor 2' />
              <Dropdown.Item text='Floor 3' />
              <Dropdown.Item text='Floor 4' />
            </Dropdown.Menu>
          </Dropdown>
          <span className="actions">
            <span onClick={this.toggleSize}>
              <Icon path={this.state.maximized ? mdiArrowCollapse : mdiArrowExpand} size={0.75} />
            </span>
          </span>
        </div>
        <div>
          <div className="marker-current" style={{
            transform: `rotate(${this.state.markerRotation}rad)`,
            left: this.state.markerLeft,
            bottom: this.state.markerBottom,
          }} />
          {
            this.config.scanLocations.map((scanLocation, index) => (
              index !== this.state.scanLocation && (
                <div
                  className="marker-other"
                  key={index}
                  style={{
                    left: `calc(${(floorplan.projectBasePoint.x + scanLocation.x) / floorplan.width * 100}% - 12px)`,
                    bottom: `calc(${(floorplan.projectBasePoint.y + scanLocation.y) / floorplan.height * 100}% - 12px)`,
                  }}
                  onClick={this.setScanLocation.bind(this, index)}
                />
              )
            ))
          }
          {
            this.state.notesVisible && this.config.notes.map((note, index) => (
              <div
                className="marker-note"
                key={index}
                style={{
                  left: `calc(${(floorplan.projectBasePoint.x + note.x) / floorplan.width * 100}% - 12px)`,
                  bottom: `calc(${(floorplan.projectBasePoint.y + note.y) / floorplan.height * 100}% - 12px)`,
                }}
              />
            ))
          }
          <div className="img-display">
            <img src={this.state.imgSrc} alt="" />
          </div>
        </div>
      </div>
    );
  }

  protected loadSync = (): void => {
    const { x, y } = this.config.currentScanLocation;
    const { width, height, projectBasePoint } = this.config.floorplan;
    const markerLeft = `calc(${(projectBasePoint.x + x) / width * 100}% - 21px)`;
    const markerBottom = `calc(${(projectBasePoint.y + y) / height * 100}% - 21px)`;
    const markerRotation = -this.viewSync.rotation.y;

    this.setState({
      markerLeft,
      markerBottom,
      markerRotation,
      scanLocation: this.config.currentScanLocationIndex,
    });
  };

  protected toggleSize = (): void => {
    this.setState({ maximized: !this.state.maximized });
  };

  protected setScanLocation(index: number): void {
    eventBus.dispatchEvent(new Contilio360Event(Contilio360Event.REQUEST_SCAN_LOCATION, index));
    this.loadSync();
  }
}