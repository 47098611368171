import { mdiClose, mdiInvertColors, mdiInvertColorsOff, mdiSquareRounded } from '@mdi/js';
import Icon from '@mdi/react';
import gsap from 'gsap';
import React, { PureComponent, ReactNode, RefObject } from 'react';
import { Button, Dimmer, Loader, Popup } from 'semantic-ui-react';
import { AmbientLight, Box3, Group, PerspectiveCamera, PointLight, Scene, Vector3, WebGLRenderer } from 'three';
import { eventBus } from '../../context/Contilio360Context';
import { LookAroundControls } from '../../controls/LookAroundControls';
import { AssetUrl } from '../../enums/AssetUrl';
import { ConfigEvent } from '../../events/ConfigEvent';
import { Contilio360Event } from '../../events/Contilio360Event';
import { ResultsLoader } from '../../loaders/ResultsLoader';
import { ConfigModel } from '../../models/ConfigModel';
import { ViewSyncModel, ViewSyncSource } from '../../models/ViewSyncModel';
import BimRenderer from '../../renderers/BimRenderer';
import { DateInput } from '../components/DateInput';
import { Floorplan } from '../components/Floorplan';
import { ZoomInput } from '../components/ZoomInput';
import './BimViewer.scss';

/**
 * Contilio 360 BIM viewer
 * @author  Neil Rackett
 */
export class BimViewer extends PureComponent<any, any> {
  protected config!: ConfigModel; // Injected
  protected viewSync!: ViewSyncModel; // Injected

  protected renderer: WebGLRenderer;
  protected scene: Scene = new Scene();
  protected camera: PerspectiveCamera = new PerspectiveCamera(50, NaN, 0.01, 2000);
  protected controls!: LookAroundControls;
  protected group: Group = new Group();
  protected bim!: BimRenderer;
  protected ref: RefObject<HTMLDivElement> = React.createRef();
  protected bimOffset: Vector3 = new Vector3();

  protected resizeObserver!: ResizeObserver;

  #animatePending: boolean = false;
  #resizePending: boolean = false;

  constructor(props: any) {
    super(props);

    eventBus.inject(this);

    this.state = {
      isLoaded: false,
      isCompact: false,
      statusEnabled: false,
    };

    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
      logarithmicDepthBuffer: true,
      powerPreference: "high-performance",
      precision: "highp",
      premultipliedAlpha: false,
      preserveDrawingBuffer: false,
    });
  }

  protected get domElement(): HTMLDivElement | null {
    return this.ref.current;
  }

  public async componentDidMount(): Promise<void> {
    const { scene, camera } = this;

    const bim = new BimRenderer(scene, camera);
    bim.statusEnabled = this.state.statusEnabled;

    const ambientLight = new AmbientLight(0xFFFFFF, 0.4);

    const pointLight = new PointLight(0xEEEEFF, 0.6);
    pointLight.position.set(0, 0, 0);
    pointLight.castShadow = true;

    camera.rotation.order = 'YXZ';
    camera.add(pointLight);

    this.bim = bim;
    this.group.add(bim.group);
    this.scene.add(camera, ambientLight, this.group);

    this.ref.current?.append(this.renderer.domElement);

    const resultsLoader = new ResultsLoader();
    const results = await resultsLoader.load(`${AssetUrl.S3_URI}/csvData.csv`);
    const bimGroup = await bim.load(`${AssetUrl.S3_URI}/bim.zip`, results);

    const bounds = new Box3().setFromObject(bimGroup);
    const center = new Vector3();

    bounds.getCenter(center);

    this.bimOffset.set(-center.x, -center.y, -center.z);
    this.group.position.copy(this.bimOffset);

    this.controls = new LookAroundControls(camera, this.renderer.domElement);
    this.controls.addEventListener(Contilio360Event.CHANGE, this.controlsChangeHandler);

    this.config.addEventListener(ConfigEvent.SCAN_LOCATION_CHANGE, this.updateScanLocation);

    this.resizeObserver = new ResizeObserver(this.resizeHandler);
    this.resizeObserver.observe(this.domElement as Element);

    this.viewSync.addEventListener(Contilio360Event.CHANGE, this.loadSync);

    this.updateScanLocation();
    this.resize();
    this.animate();
    this.setState({ isLoaded: true });
  }

  public componentWillUnmount(): void {
    this.controls.removeEventListener(Contilio360Event.CHANGE, this.controlsChangeHandler);
    this.controls.dispose();

    this.config.removeEventListener(ConfigEvent.SCAN_LOCATION_CHANGE, this.updateScanLocation);

    this.resizeObserver.unobserve(this.domElement as Element);
    this.resizeObserver.disconnect();

    this.viewSync.removeEventListener(Contilio360Event.CHANGE, this.loadSync);

    eventBus.uninject(this);
  }

  public render(): ReactNode {
    const closeVisible = !!this.props.closeVisible;
    const tooltipDelay = 1000;
    const tooltipPosition = 'right center';

    return (
      <div className="BimViewer" ref={this.ref}>
        <Dimmer active={!this.state.isLoaded} inverted>
          <Loader size="massive" />
        </Dimmer>

        <div className="overlay">
          {closeVisible && (
            <Popup content="Close BIM" inverted position={tooltipPosition} mouseEnterDelay={tooltipDelay} trigger={
              <Button icon className="close not-mobile" onClick={this.close}>
                <Icon path={mdiClose} size={1} />
              </Button>
            } />
          )}

          <Popup content={this.state.statusEnabled ? 'Hide status insights' : 'Show status insights'} inverted position={tooltipPosition} mouseEnterDelay={tooltipDelay} trigger={
            <Button icon className="toggle-status" onClick={this.toggleStatus}>
              <Icon path={this.state.statusEnabled ? mdiInvertColors : mdiInvertColorsOff} size={1} />
            </Button>
          } />

          <Popup content={closeVisible ? 'Close panorama' : 'Open panorama'} inverted position={tooltipPosition} mouseEnterDelay={tooltipDelay} trigger={
            <Button icon className="toggle-split panorama" onClick={this.toggleSplit} />
          } />

          <ZoomInput className="zoom" />

          {!closeVisible && (
            <DateInput className="date" compact={this.state.isCompact} />
          )}

          <Floorplan className="not-mobile" />

          {this.state.statusEnabled && (
            <div className="status-key">
              <div><Icon path={mdiSquareRounded} size={1} color="#B7B7B7" /> Built Correctly</div>
              <div><Icon path={mdiSquareRounded} size={1} color="#B33636" /> Behind Schedule</div>
              <div><Icon path={mdiSquareRounded} size={1} color="#B3B336" /> Out of Tolerance</div>
              <div><Icon path={mdiSquareRounded} size={1} color="#5E80A3" /> Not Analysed</div>
            </div>
          )}

        </div>

      </div>
    );
  }

  protected resizeHandler = (): void => {
    this.#resizePending = true;
  };

  protected resize = (): void => {
    if (this.domElement) {
      const { camera, renderer } = this;
      const { width, height } = this.domElement.getBoundingClientRect();

      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      renderer.setSize(width, height);

      const isCompact = this.domElement.clientWidth <= 375;
      this.setState({ isCompact });
    }
  };

  protected animate = (delta: DOMHighResTimeStamp = 0.0): void => {
    const animatePending = this.#resizePending || this.#animatePending;

    if (this.#resizePending) {
      this.resize();
      this.#resizePending = false;
    }

    if (animatePending) {
      this.renderer.render(this.scene, this.camera);
      this.#animatePending = false;
    }

    requestAnimationFrame(this.animate);
  };

  protected updateScanLocation = (event?: ConfigEvent): void => {
    const { currentScanLocation } = this.config;

    const toPosition = {
      x: this.bimOffset.x + currentScanLocation.x,
      y: this.bimOffset.y + currentScanLocation.z,
      z: this.bimOffset.z - currentScanLocation.y
    };

    if (event) {
      gsap.to(this.camera.position, {
        ...toPosition,
        delay: 0.2,
        duration: 0.9,
        ease: "sine.out",
        onUpdate: () => {
          this.camera.updateProjectionMatrix();
          this.#animatePending = true;
        },
        onComplete: () => this.loadSync()
      });

      this.#animatePending = true;
    } else {
      Object.assign(this.camera.position, toPosition);
      this.loadSync(true);
    }
  };

  protected controlsChangeHandler = (): void => {
    this.saveSync();
    this.#animatePending = true;
  };

  protected saveSync = (): void => {
    const rotation = this.camera.rotation;
    const zoom = (this.camera.zoom - this.controls.zoomMin) / (this.controls.zoomMax - this.controls.zoomMin);
    const source = ViewSyncSource.BIM;

    this.viewSync.set(rotation, zoom, source);

    if (this.props.onChange) {
      this.props.onChange({ rotation, zoom, source });
    }
  };

  protected loadSync = (force: boolean = false): void => {
    if (force === true || this.viewSync.source !== ViewSyncSource.BIM) {
      this.camera.setRotationFromEuler(this.viewSync.rotation);
      this.camera.zoom = this.controls.zoomMin + (this.viewSync.zoom * (this.controls.zoomMax - this.controls.zoomMin));
      this.camera.updateProjectionMatrix();
      this.#animatePending = true;
    }
  };

  protected close = (): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  protected toggleSplit = (): void => {
    if (this.props.onToggleSplit) {
      this.props.onToggleSplit(ViewSyncSource.BIM);
    }
  };

  protected toggleStatus = (): void => {
    this.bim.statusEnabled = !this.bim.statusEnabled;
    this.setState({ statusEnabled: this.bim.statusEnabled });
    this.#animatePending = true;
  };
}
