/**
 * Utility methods for array and array-like datasets
 * @author  Neil Rackett
 */
export const ArrayUtils = {
  /**
   * 100% faster than Array.includes() for large arrays 🤷
   */
  includes: (haystack: any[], needle: any): boolean => {
    const numPointSourceIDs = haystack.length;
    for (let i = 0; i < numPointSourceIDs; i++) {
      if (haystack[i] === needle) {
        return true;
      }
    }

    return false;
  },

};
