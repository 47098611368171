import { Command } from "conbine";
import { eventBus } from "../context/Contilio360Context";
import { ConfigModel } from "../models/ConfigModel";
import { ViewSyncModel } from "../models/ViewSyncModel";

export class RequestScanLocationCommand extends Command {
  protected config!: ConfigModel; // Injected
  protected viewSync!: ViewSyncModel; // Injected

  public async execute(): Promise<void> {
    eventBus.inject(this);
    this.config.currentScanLocationIndex = ~~this.event.data;
    // this.viewSync.reset();
  }
}
