import React, { PureComponent, ReactNode } from "react";
import { Menu } from "semantic-ui-react";
import './PanoramaContextMenu.scss';

/**
 * Right-click context menu for panorama viewer
 * @author  Neil Rackett
 */
export class PanoramaContextMenu extends PureComponent<any, any> {
  public render(): ReactNode {
    if (this.props.open) {
      return (
        <div
          className="PanoramaContextMenu"
          style={{
            left: this.props.open.x,
            top: this.props.open.y,
          }}
        >
          <Menu vertical>
            <Menu.Item onClick={this.createNote}>
              Add field note...
            </Menu.Item>
          </Menu>
        </div>
      );
    }

    return null;
  }

  protected createNote = (): void => {
    if (this.props.onCreateNote) {
      this.props.onCreateNote();
    }

    this.close();
  };

  protected close = (): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
}