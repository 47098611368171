import { EventDispatcher } from "conbine";
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { Vector2 } from "three";
import { ConfigEvent } from "../events/ConfigEvent";

gsap.registerPlugin(CustomEase);
export interface IHotSpot {
  // Pannellum properties
  pitch: number,
  yaw: number,
  type: string,
  text?: string,
  URL?: string,
  attributes?: any,
  sceneId?: string,
  targetPitch?: number,
  targetYaw?: number,
  targetHfov?: number,
  id?: any,
  cssClass?: string,
  createTooltipFunc?: Function,
  createTooltipArgs?: any,
  clickHandlerFunc?: Function,
  clickHandlerArgs?: any,
  scale?: boolean,

  // Custom properties
  noteId?: string,
}

export interface INote {
  id?: string,
  title?: string,
  note?: string,
  date?: string,
  user?: string;
  x: number;
  y: number;
  z: number;
}

export interface IScanLocation {
  id: string,
  captureDate: Date,
  image: string,
  x: number,
  y: number,
  z: number,
  projectNorthOffset: number,
  hotSpots: IHotSpot[],
  vector: Vector2,
}

export interface IFloorplan {
  image: string,
  width: number,
  height: number,
  projectBasePoint: {
    x: number,
    y: number,
  };
}

export interface IConfig {
  scanName: string,
  company: string,
  project: string,
  reportDate: Date,
  floorplan: IFloorplan,
  scanLocations: IScanLocation[],
  notes: INote[],
}

/**
 * Custom easing equation used to approximate perspective and place the scene
 * hotspots in a visually pleasing way based on distance
 *
 * To edit, use https://greensock.com/docs/v3/Eases/CustomEase
 *
 * @author  Neil Rackett
 */
const forcePerspective = CustomEase.create("forcePerspective", "M0,0,C0.5,0,0.703,0.05,0.748,0.078,0.974,0.218,0.932,0.244,1,1");

/**
 * Application config and data
 * @author  Neil Rackett
 */
export class ConfigModel extends EventDispatcher implements IConfig {
  public scanName!: string;
  public company!: string;
  public project!: string;
  public floorplan!: IFloorplan;
  public notes!: INote[];

  public currentFloor: number = 1;

  #scanLocations: IScanLocation[] = [];
  #reportDate!: Date;
  #currentScanLocationIndex = 0;

  constructor(config?: IConfig) {
    super();
    this.set(config);
  }

  public get reportDate(): Date {
    return this.#reportDate;
  }
  public set reportDate(value: Date | number | string) {
    this.#reportDate = new Date(value);
  }

  public get scanLocations(): IScanLocation[] {
    return this.#scanLocations;
  }
  public set scanLocations(value: IScanLocation[]) {
    const vectors = value.map((scanLocation, index) => {
      const vector = new Vector2(scanLocation.x, scanLocation.y);
      scanLocation.vector = vector;
      return vector;
    });

    this.#scanLocations = value.map((scanLocation, index) => {
      const hotSpots = (scanLocation.hotSpots || []).map((hotSpot, h) => {
        const newHotSpot = {
          ...hotSpot,
          id: h.toString(),
          cssClass: `hotspot ${hotSpot.type}`,
        };

        if (hotSpot.type === 'scene') {
          const p1 = vectors[index];
          const p2 = vectors[~~(hotSpot.sceneId || 0)];
          const maxDistance = 43; // TODO This will need to be loaded / calculated in future?

          // Pitch uses an arc length calculation combined with a custom easing equation to force perspective
          newHotSpot.pitch = Math.min(-2.25, (forcePerspective(1 - p1.distanceTo(p2) / maxDistance)) * -((Math.PI / 2) / (2 * Math.PI) * 360)); // 0 to -90 degrees

          newHotSpot.yaw = 90 - (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI) - scanLocation.projectNorthOffset;
        }

        return newHotSpot;
      });

      return {
        ...scanLocation,
        id: index.toString(),
        captureDate: new Date(scanLocation.captureDate),
        hotSpots,
      };
    });
  }

  public get numScanLocations(): number {
    return this.scanLocations.length;
  }

  public get currentScanLocation(): IScanLocation {
    return this.scanLocations[this.#currentScanLocationIndex];
  }

  public get currentScanLocationIndex(): number {
    return this.#currentScanLocationIndex;
  }
  public set currentScanLocationIndex(value: number) {
    if (value !== this.#currentScanLocationIndex) {
      this.#currentScanLocationIndex = Math.max(0, Math.min(value, this.scanLocations.length - 1));
      this.dispatchEvent(new ConfigEvent(ConfigEvent.SCAN_LOCATION_CHANGE, this.#currentScanLocationIndex));
    }
  }

  public set(config?: IConfig) {
    Object.assign(this, config);
  }
}
