import { mdiUnfoldMoreHorizontal, mdiUnfoldMoreVertical } from '@mdi/js';
import Icon from '@mdi/react';
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from 'react-dom';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { ViewSyncSource } from "../models/ViewSyncModel";
import { BimViewer } from './viewers/BimViewer';
import { PannellumViewer } from './viewers/PannellumViewer';
import './ViewersView.scss';

/**
 * Main application area containing a split view, with the panorama on the
 * left and BIM on the right
 *
 * @author  Neil Rackett
 */
export class ViewersView extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      bimVisible: true,
      panoramaVisible: true,
      orientation: 'vertical',
      splitSize: undefined,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  public render(): ReactNode {
    const { orientation } = this.state;
    const handleIconPath = orientation === 'vertical' ? mdiUnfoldMoreVertical : mdiUnfoldMoreHorizontal;

    return (
      <ReflexContainer className="ViewersView" orientation={orientation} windowResizeAware >
        {this.state.panoramaVisible && (
          <ReflexElement className="left-pane" minSize={225} size={this.state.splitSize}>
            <PannellumViewer
              closeVisible={this.state.bimVisible}
              onClose={this.panoramaCloseHandler}
              onToggleSplit={this.toggleSplit}
            />
          </ReflexElement>
        )}
        {this.state.panoramaVisible && this.state.bimVisible && (
          <ReflexSplitter>
            <div className="handle" onDoubleClick={this.resetSplit}>
              <Icon path={handleIconPath} size={2} color="white" />
            </div>
          </ReflexSplitter>
        )}
        {this.state.bimVisible && (
          <ReflexElement className="right-pane" minSize={225}>
            <BimViewer
              closeVisible={this.state.panoramaVisible}
              onClose={this.bimCloseHandler}
              onToggleSplit={this.toggleSplit}
            />
          </ReflexElement>
        )}
      </ReflexContainer>
    );
  }

  protected resizeHandler = (event?: Event): void => {
    const orientation = window.innerWidth >= window.innerHeight ? 'vertical' : 'horizontal';
    this.setState({ orientation });
  };

  protected panoramaCloseHandler = (): void => {
    this.setState({ panoramaVisible: false });
  };

  protected bimCloseHandler = (): void => {
    this.setState({ bimVisible: false });
  };

  protected toggleSplit = (source: ViewSyncSource): void => {
    switch (source) {
      case ViewSyncSource.BIM:
        this.setState({ panoramaVisible: !this.state.panoramaVisible });
        break;
      case ViewSyncSource.PANORAMA:
        this.setState({ bimVisible: !this.state.bimVisible });
        break;
    }
  };

  protected resetSplit = (): void => {
    const el = ReactDOM.findDOMNode(this) as HTMLElement;
    const splitSize = el.getBoundingClientRect().width / 2;

    this.setState({ splitSize }, () => this.setState({ splitSize: undefined }));
  };
}
