import { ConbineEvent } from 'conbine';

/**
 * Contilio 260 event
 * @author  Neil Rackett
 */
export class Contilio360Event extends ConbineEvent {
  public static readonly STARTUP: string = 'startup';
  public static readonly CHANGE: string = 'change';
  public static readonly REQUEST_SCAN_LOCATION: string = 'requestScanLocation';
}
