import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import React, { PureComponent, ReactNode } from "react";
import { Direction, Range } from "react-range";
import { eventBus } from "../../context/Contilio360Context";
import { Contilio360Event } from "../../events/Contilio360Event";
import { ViewSyncModel } from "../../models/ViewSyncModel";
import './ZoomInput.scss';

const MIN = 0;
const MAX = 1;
const STEP = 0.025;

interface IZoomInputProps {
  className?: string;
}

interface IZoomInputState {
  values: number[];
}

/**
 * Vertical zoom level selector
 * @author  Neil Rackett
 */
export class ZoomInput extends PureComponent<IZoomInputProps, IZoomInputState> {
  protected viewSync!: ViewSyncModel; // Injected

  constructor(props: IZoomInputProps) {
    super(props);
    this.state = { values: [0] };
  }

  public componentDidMount(): void {
    eventBus.inject(this);
    this.viewSync.addEventListener(Contilio360Event.CHANGE, this.loadSync);
    this.loadSync();
  }

  public componentWillUnmount(): void {
    this.viewSync.removeEventListener(Contilio360Event.CHANGE, this.loadSync);
    eventBus.uninject(this);
  }

  public render(): ReactNode {
    const classList = ['ZoomInput'];

    if (this.props.className) {
      classList.push(this.props.className);
    }

    return (
      <div className={classList.join(' ')}>
        <Range
          step={STEP}
          min={MIN}
          max={MAX}
          draggableTrack={false}
          values={this.state.values}
          direction={Direction.Up}

          onChange={this.#changeHandler}

          renderThumb={this.#renderThumb}
          renderTrack={this.#renderTrack}
        />
      </div>
    );
  }

  #renderThumb = ({ props }: any): ReactNode => {
    return (
      <div
        {...props}
        className="thumb"
        style={{ ...props.style }}
      >
        <Icon path={mdiMagnify} size={1} />
      </div>
    );
  };

  #renderTrack = ({ props, children }: any): ReactNode => {
    return (
      <div
        {...props}
        className="track"
        style={{ ...props.style }}
      >
        <div className="fill" />
        {children}
      </div>
    );
  };

  #changeHandler = (values: number[]) => {
    this.setState({ values }, () => {
      this.viewSync.setZoom(values[0]);
    });
  };

  protected loadSync = (): void => {
    if (this.state.values[0] !== this.viewSync.zoom) {
      this.setState({ values: [this.viewSync.zoom] });
    }
  };
}
