import { ConbineEvent, EventDispatcher } from "conbine";
import { Euler } from "three";
import { Contilio360Event } from "../events/Contilio360Event";
import { IViewInfo } from "./IViewInfo";

export enum ViewSyncSource {
  OTHER = 0,
  BIM = 1,
  PANORAMA = 2,
}

export class ViewSyncModel extends EventDispatcher implements IViewInfo {
  public rotation!: Euler;
  public zoom!: number;
  public source!: ViewSyncSource;

  constructor() {
    super();
    this.reset();
  }

  public set(rotation: Euler, zoom: number, source: ViewSyncSource = ViewSyncSource.OTHER): void {
    let hasChanged = false;

    if (!this.rotation?.equals(rotation)) {
      this.rotation = rotation.clone();
      hasChanged = true;
    }

    if (this.zoom !== zoom) {
      this.zoom = zoom;
      hasChanged = true;
    }

    if (hasChanged) {
      this.source = source;
      this.dispatchEvent(new ConbineEvent(Contilio360Event.CHANGE));
    }
  }

  public reset(): void {
    this.rotation = new Euler(0, 0, 0, 'YXZ');
    this.zoom = 0;
    this.source = ViewSyncSource.OTHER;

    this.dispatchEvent(new ConbineEvent(Contilio360Event.CHANGE));
  }

  // TODO Replace with accessors
  public setZoom(value: number, source: ViewSyncSource = ViewSyncSource.OTHER): void {
    if (this.zoom !== value) {
      this.zoom = value;
      this.source = source;
      this.dispatchEvent(new ConbineEvent(Contilio360Event.CHANGE));
    }
  }
}
