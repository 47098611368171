export const ColorUtils = {
  adjustBrightness(color: number, percent: number): number {
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const r = color >> 16;
    const g = (color >> 8) & 0x00FF;
    const b = color & 0x0000FF;

    return (0x1000000 + (Math.round((t - r) * p) + r) * 0x10000 + (Math.round((t - g) * p) + g) * 0x100 + (Math.round((t - b) * p) + b));
  }
};
