import { EventDispatcher } from 'conbine';
import { Euler, PerspectiveCamera, Vector3 } from 'three';
import { Contilio360Event } from '../events/Contilio360Event';

const PI_2 = Math.PI / 2;

/**
 * Look Around Controls
 *
 * Mouse controls loosely based on Three.js PointerLockControls but without
 * physical movement, to approximate Pannellum's navigation method.
 *
 * @author  Neil Rackett
 */
export class LookAroundControls extends EventDispatcher {
  // Set to constrain the zoom of the camera: range is 0.0 to 1.0
  public zoomMin: number = 0.285;
  public zoomMax: number = 0.900;

  // Set to constrain the pitch of the camera: range is 0 to Math.PI radians
  public polarAngleMin: number = 0.0;
  public polarAngleMax: number = Math.PI;

  #enabled: boolean = true;
  #camera: PerspectiveCamera;
  #domElement: HTMLElement;

  #isLocked: boolean = false;

  #euler = new Euler(0, 0, 0, 'YXZ');

  #prevTime: DOMHighResTimeStamp = 0;

  constructor(camera: PerspectiveCamera, domElement: HTMLElement) {
    super();

    this.#camera = camera;

    this.#domElement = domElement;
    this.#domElement.style.cursor = 'grab';

    this.connect();
  }

  get enabled(): boolean {
    return this.#enabled;
  }
  set enabled(value: boolean) {
    if (value !== this.#enabled) {
      this.#enabled = value;

      if (value) {
        this.#prevTime = performance.now();
        this.lookAhead();
      }
    }
  }

  public connect() {
    const { ownerDocument } = this.#domElement;
    this.#domElement.addEventListener('touchstart', this.#touchStartHandler);
    this.#domElement.addEventListener('pointerdown', this.#pointerDownHandler);
    this.#domElement.addEventListener('wheel', this.#wheelHandler);
    this.#domElement.addEventListener('dblclick', this.#dblClickHandler);
    ownerDocument.addEventListener('pointermove', this.#pointerMoveHandler);
  }

  public disconnect() {
    const { ownerDocument } = this.#domElement;
    this.#domElement.removeEventListener('touchstart', this.#touchStartHandler);
    this.#domElement.removeEventListener('pointerdown', this.#pointerDownHandler);
    this.#domElement.removeEventListener('wheel', this.#wheelHandler);
    this.#domElement.addEventListener('dblclick', this.#dblClickHandler);
    ownerDocument.removeEventListener('pointermove', this.#pointerMoveHandler);
  }

  public dispose() {
    this.disconnect();
  }

  public rotateRight(angle: number) {
    this.#camera.rotateOnAxis(new Vector3(0, 1, 0), angle);
  }

  public lock(): void {
    this.#isLocked = true;
  }

  public unlock(): void {
    this.#isLocked = false;
  }

  /**
   * Rotates the camera so that the first person is stood up straight and looking forward
   */
  public lookAhead(): void {
    const { rotation } = this.#camera;
    const newRotation = new Vector3(0, rotation.y, 0);

    this.#camera.rotation.set(newRotation.x, newRotation.y, newRotation.z);
  }

  #touchStartHandler = (event: TouchEvent): void => {
    event.preventDefault();
  };

  #prevDiff = 0;
  #pointerEventCache: PointerEvent[] = [];

  #pointerDownHandler = (event: PointerEvent): void => {
    if (this.enabled) {

      this.#pointerEventCache.push(event);
      this.#domElement.style.cursor = 'grabbing';
      this.#domElement.ownerDocument.addEventListener('pointerup', this.#pointerUpHandler);
      this.lock();

      event.preventDefault();
    }
  };

  #pointerMoveHandler = (event: PointerEvent): void => {
    if (this.enabled && this.#isLocked) {

      for (var i = 0; i < this.#pointerEventCache.length; i++) {
        if (event.pointerId === this.#pointerEventCache[i].pointerId) {
          this.#pointerEventCache[i] = event;
          break;
        }
      }

      // Zoom
      if (this.#pointerEventCache.length === 2) {
        const { zoom } = this.#camera;
        const curDiff = Math.abs(this.#pointerEventCache[0].clientX - this.#pointerEventCache[1].clientX);

        let delta = 0.0;

        if (this.#prevDiff) {
          if (curDiff > this.#prevDiff) {
            delta = 0.01;
          } else if (curDiff < this.#prevDiff) {
            delta = -0.01;
          }
        }

        this.#camera.zoom = Math.max(this.zoomMin, Math.min(zoom + delta, this.zoomMax));
        this.#camera.updateProjectionMatrix();
        this.dispatchEvent(new Contilio360Event(Contilio360Event.CHANGE));

        // Cache the distance for the next move event
        this.#prevDiff = curDiff;

        return;
      }

      const movementX = -event.movementX || 0;
      const movementY = -event.movementY || 0;

      this.#euler.setFromQuaternion(this.#camera.quaternion);
      this.#euler.y -= movementX * 0.003;
      this.#euler.x -= movementY * 0.003;
      this.#euler.x = Math.max(PI_2 - this.polarAngleMax, Math.min(PI_2 - this.polarAngleMin, this.#euler.x));

      this.#camera.quaternion.setFromEuler(this.#euler);

      event.preventDefault();
      this.dispatchEvent(new Contilio360Event(Contilio360Event.CHANGE));
    }
  };

  #pointerUpHandler = (event: PointerEvent): void => {
    if (this.enabled) {
      this.#domElement.style.cursor = 'grab';
      this.#domElement.ownerDocument.removeEventListener('pointerup', this.#pointerUpHandler);
      this.unlock();
      event.preventDefault();

      // Zoom
      this.#prevDiff = 0.0;
      this.#pointerEventCache = [];
    }
  };

  #wheelHandler = (event: WheelEvent): void => {
    if (this.enabled) {
      const delta = event.deltaY / 5000;
      const { zoom } = this.#camera;

      this.#camera.zoom = Math.max(this.zoomMin, Math.min(zoom - delta, this.zoomMax));
      this.#camera.updateProjectionMatrix();
      this.dispatchEvent(new Contilio360Event(Contilio360Event.CHANGE));
    }
  };

  #dblClickHandler = (event: MouseEvent): void => {
    if (this.#camera.zoom < this.zoomMax) {
      this.#camera.zoom = this.zoomMax;
    } else {
      this.#camera.zoom = 0.4;
    }
    this.#camera.updateProjectionMatrix();
    this.dispatchEvent(new Contilio360Event(Contilio360Event.CHANGE));
  };
}
