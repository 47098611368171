
import { mdiCameraPlus, mdiClose, mdiInformation, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import React, { PureComponent, ReactNode, RefObject } from 'react';
import { Button, Dimmer, Loader, Modal, Popup, Table } from 'semantic-ui-react';
import { Euler, Vector3 } from 'three';
import { eventBus } from '../../context/Contilio360Context';
import { AssetUrl } from '../../enums/AssetUrl';
import { ConfigEvent } from '../../events/ConfigEvent';
import { Contilio360Event } from '../../events/Contilio360Event';
import { ConfigModel, INote } from '../../models/ConfigModel';
import { ViewSyncModel, ViewSyncSource } from '../../models/ViewSyncModel';
import { DateInput } from '../components/DateInput';
import { Floorplan } from '../components/Floorplan';
import { NoteViewer } from '../components/NoteViewer';
import { PanoramaContextMenu } from '../components/PanoramaContextMenu';
import { ZoomInput } from '../components/ZoomInput';
import './PannellumViewer.scss';

/**
 * Contilio panorama viewer
 * @author  Neil Rackett
 */
export class PannellumViewer extends PureComponent<any, any> {
  protected config!: ConfigModel; // Injected
  protected viewSync!: ViewSyncModel; // Injected

  protected contextRef: RefObject<HTMLDivElement> = React.createRef();
  protected panoramaRef: RefObject<HTMLDivElement> = React.createRef();
  protected viewer: any;
  protected resizeObserver!: ResizeObserver;
  protected isInteracting: boolean = false;

  #resizePending = false;
  #animationFrame = 0;
  #fadeTransformOrigin: Vector3 | undefined;

  constructor(props: any) {
    super(props);

    eventBus.inject(this);

    this.state = {
      isLoaded: false,
      isSceneLoaded: false,
      isCompact: false,
      infoVisible: false,
      contextMenuOpen: null,
      selectedNote: null,
      title: this.config.scanName,
    };
  }

  public componentDidMount(): void {
    this.config.addEventListener(ConfigEvent.SCAN_LOCATION_CHANGE, this.updateScanLocation);

    this.resizeObserver = new ResizeObserver(this.resizeHandler);
    this.resizeObserver.observe(this.panoramaRef.current as Element);

    this.viewSync.addEventListener(Contilio360Event.CHANGE, this.loadSync);

    const { scanLocations, currentScanLocation } = this.config;
    const { pannellum } = window as any; // @see https://pannellum.org/documentation/reference/

    const scenes: any = {};

    scanLocations.forEach((scanLocation, index) => {
      scenes[scanLocation.id] = {
        type: 'equirectangular',
        panorama: `${AssetUrl.S3_URI}/panoramas/${scanLocation.image}`,
        pitch: 0.0,
        yaw: -scanLocation.projectNorthOffset,
        northOffset: scanLocation.projectNorthOffset,
        hotSpots: (scanLocation.hotSpots || []).map(hotSpot => {
          if (hotSpot.type === 'info') {
            const note = this.config.notes.find(note => note.id === hotSpot.noteId);
            return {
              ...hotSpot,
              clickHandlerFunc: this.noteHandler,
              clickHandlerArgs: note,
            };
          }
          return {
            ...hotSpot,
            scale: 'pitch',
            clickHandlerFunc: this.sceneHandler,
            clickHandlerArgs: hotSpot,
          };
        }),
      };
    });

    const viewer = pannellum.viewer(this.panoramaRef.current, {
      default: {
        firstScene: currentScanLocation.id,
        sceneFadeDuration: 1000,
        sceneFadeFunction: this.fadeScene,
        contextMenuEnabled: false,
        // hotSpotDebug: true,
        // minPitch: -120,
        // maxPitch: 120,
      },
      autoLoad: true,
      showControls: false,
      friction: 1.0,
      scenes,
    });

    viewer.on('load', this.loadHandler);
    viewer.on('zoomchange', this.saveSync);
    viewer.on('mousedown', this.interactionStartHandler);
    viewer.on('touchstart', this.interactionStartHandler);
    viewer.on('animatefinished', this.interactionEndHandler);
    viewer.on('scenechange', this.sceneChangeHandler);

    this.viewer = viewer;

    this.contextRef.current?.addEventListener('contextmenu', this.contextMenuHandler);

    this.updateScanLocation();
    this.resize();

    // console.log("Pannellum:", this.viewer);
  }

  public componentWillUnmount(): void {
    this.config.removeEventListener(ConfigEvent.SCAN_LOCATION_CHANGE, this.updateScanLocation);

    this.resizeObserver.unobserve(this.panoramaRef.current as Element);
    this.resizeObserver.disconnect();

    this.viewSync.removeEventListener(Contilio360Event.CHANGE, this.loadSync);

    const { viewer } = this;

    viewer.off('load', this.loadHandler);
    viewer.off('zoomchange', this.saveSync);
    viewer.off('mousedown', this.interactionStartHandler);
    viewer.off('touchstart', this.interactionStartHandler);
    viewer.off('animatefinished', this.interactionEndHandler);
    viewer.off('scenechange', this.sceneChangeHandler);
    viewer.destroy();
  }

  public render(): ReactNode {
    const closeVisible = !!this.props.closeVisible;
    const tooltipDelay = 1000;
    const tooltipPosition = 'right center';

    return (
      <div className="PannellumViewer" ref={this.contextRef}>
        <Dimmer
          active={!(this.state.isLoaded && this.state.isSceneLoaded)}
          className={this.state.isLoaded && !this.state.isSceneLoaded ? 'scene' : ''}
          inverted
        >
          <Loader size="massive" />
        </Dimmer>

        <div className="overlay">
          <div className="title">
            {this.state.title}
            <span className="edit" onClick={this.editTitle}>
              <Icon path={mdiPencil} size={1} />
            </span>
            <div className="subtitle">
              Capture {this.config.currentScanLocationIndex + 1} of {this.config.numScanLocations}
            </div>
          </div>

          {closeVisible && (
            <Popup content="Close panorama" inverted position={tooltipPosition} mouseEnterDelay={tooltipDelay} trigger={
              <Button icon className="close not-mobile" onClick={this.close}>
                <Icon path={mdiClose} size={1} />
              </Button>
            } />
          )}

          <Popup content="About this scan" inverted position={tooltipPosition} mouseEnterDelay={tooltipDelay} trigger={
            <Button icon className="info not-mobile" onClick={this.showScanInfo}>
              <Icon path={mdiInformation} size={1} />
            </Button>
          } />
          <Popup content="Upload new captures" inverted position={tooltipPosition} mouseEnterDelay={tooltipDelay} trigger={
            <Button icon className="camera not-mobile" onClick={this.showUploadCaptures}>
              <Icon path={mdiCameraPlus} size={1} />
            </Button>
          } />

          <Popup content={closeVisible ? 'Close BIM' : 'Open BIM'} inverted position={tooltipPosition} mouseEnterDelay={tooltipDelay} trigger={
            <Button icon className="toggle-split bim" onClick={this.toggleSplit} />
          } />

          <ZoomInput className="zoom" />
          <DateInput className="date" compact={this.state.isCompact} />
          <Floorplan className="not-mobile" />

        </div>

        <Modal
          onClose={this.hideScanInfo}
          open={this.state.infoVisible}
          dimmer={{ blurring: true, inverted: true }}
          size='tiny'
        >
          <Modal.Header>
            About this scan
            <span className="close" onClick={this.hideScanInfo}>
              <Icon path={mdiClose} size={1} />
            </span>
          </Modal.Header>
          <Modal.Content className="panorama-info-content">
            <Modal.Description>
              <Table basic="very" padded>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={5}>Company</Table.Cell>
                    <Table.Cell>{this.config.company}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Project</Table.Cell>
                    <Table.Cell>{this.config.project}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Floor</Table.Cell>
                    <Table.Cell>{this.config.currentFloor}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Report date</Table.Cell>
                    <Table.Cell>{this.config.reportDate.toLocaleDateString()}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Capture number</Table.Cell>
                    <Table.Cell>{`${this.config.currentScanLocationIndex + 1} of ${this.config.scanLocations.length}`}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Capture date</Table.Cell>
                    <Table.Cell>{this.config.currentScanLocation.captureDate.toLocaleString()}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Capture location</Table.Cell>
                    <Table.Cell>{`${this.config.currentScanLocation.x.toFixed(3)}, ${this.config.currentScanLocation.y.toFixed(3)}`}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        <Modal
          onClose={this.hideUploadCapture}
          open={this.state.uploadVisible}
          dimmer={{ blurring: true, inverted: true }}
          size='tiny'
        >
          <Modal.Header>
            Upload new captures
            <span className="close" onClick={this.hideUploadCapture}>
              <Icon path={mdiClose} size={1} />
            </span>
          </Modal.Header>
          <Modal.Content className="panorama-upload-captures-content">
            <Modal.Description>
              <p>Drag &amp; drop your scan(s) here to upload</p>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        <NoteViewer
          note={this.state.selectedNote}
          onClose={() => this.setState({ selectedNote: null })}
          onSave={this.saveNote}
          onDelete={this.deleteNote}
        />

        <PanoramaContextMenu
          open={this.state.contextMenuOpen}
          onClose={() => this.setState({ contextMenuOpen: false })}
          onCreateNote={this.createNote}
          onSave={this.saveNote}
        />

        <div className="panorama" ref={this.panoramaRef} />
      </div>
    );
  }

  protected updateScanLocation = (): void => {
    const { viewer } = this;

    if (viewer.getScene() !== this.config.currentScanLocation.id) {
      const { currentScanLocation } = this.config;
      viewer.loadScene(currentScanLocation.id);
    }
  };

  protected resizeHandler = (): void => {
    if (!this.#resizePending) {
      requestAnimationFrame(this.resize);
    }
  };

  protected resize = (): void => {
    this.viewer.resize();
    this.#resizePending = false;

    const isCompact = (this.panoramaRef.current?.clientWidth || 0) <= 375;
    this.setState({ isCompact });
  };

  protected interactionStartHandler = (): void => {
    this.isInteracting = true;
    this.changeHandler();
  };

  protected interactionEndHandler = (): void => {
    this.isInteracting = false;
    cancelAnimationFrame(this.#animationFrame);
  };

  protected changeHandler = (): void => {
    if (this.isInteracting) {
      this.saveSync();
      this.#animationFrame = requestAnimationFrame(this.changeHandler);
    }
  };

  protected loadHandler = (): void => {
    this.loadSync(true);
    this.setState({ isLoaded: true, isSceneLoaded: true });
  };

  protected saveSync = (): void => {
    if (this.viewer.isLoaded()) {
      const rotation = new Euler(
        this.viewer.getPitch() * Math.PI / 180,
        -(this.viewer.getYaw() + this.config.currentScanLocation.projectNorthOffset) * Math.PI / 180,
        0, 'YXZ'
      );

      // console.log("PITCH", this.viewer.getPitch(), ">>>", "YAW", this.viewer.getYaw());

      const zoom = 1 - ((this.viewer.getHfov() - 50) / 70);
      const source = ViewSyncSource.PANORAMA;

      this.viewSync.set(rotation, zoom, source);

      if (this.props.onChange) {
        this.props.onChange({ rotation, zoom, source });
      }
    }
  };

  protected loadSync = (force: boolean = false): void => {
    const { viewer, viewSync } = this;

    if (viewer && (force === true || viewSync.source !== ViewSyncSource.PANORAMA)) {
      viewer.setPitch(viewSync.rotation.x * 180 / Math.PI, false);
      viewer.setYaw(-viewSync.rotation.y * 180 / Math.PI - this.config.currentScanLocation.projectNorthOffset, false);
      viewer.setHfov(50 + (1 - viewSync.zoom) * 70, false);
    };
  };

  protected close = (): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  protected toggleSplit = (): void => {
    if (this.props.onToggleSplit) {
      this.props.onToggleSplit(ViewSyncSource.PANORAMA);
    }
  };

  protected showScanInfo = (): void => {
    this.setState({ infoVisible: true });
  };

  protected hideScanInfo = (): void => {
    this.setState({ infoVisible: false });
  };

  protected showUploadCaptures = (): void => {
    this.setState({ uploadVisible: true });
  };

  protected hideUploadCapture = (): void => {
    this.setState({ uploadVisible: false });
  };

  protected sceneChangeHandler = (sceneId: string): void => {
    eventBus.dispatchEvent(new Contilio360Event(Contilio360Event.REQUEST_SCAN_LOCATION, ~~sceneId));
    this.setState({ isSceneLoaded: false });
  };

  protected noteHandler = (event: PointerEvent, selectedNote: any): void => {
    this.setState({ selectedNote });
  };

  protected sceneHandler = (event: PointerEvent, hotSpot: any): void => {
    const from = ~~this.viewer.getScene();
    const to = ~~hotSpot.sceneId;
    const distance = this.config.scanLocations[from].vector.distanceTo(this.config.scanLocations[to].vector);

    this.#fadeTransformOrigin = new Vector3(event.x, event.y, distance);
  };

  protected contextMenuHandler = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    const rect = this.contextRef.current?.getBoundingClientRect();

    this.setState({
      contextMenuOpen: {
        x: event.clientX - (rect?.x || 0),
        y: event.clientY - (rect?.y || 0),
        coords: this.viewer.mouseEventToCoords(event),
      }
    });

    const clickHandler = () => {
      this.setState({ contextMenuOpen: null });
      document.removeEventListener('click', clickHandler);
    };

    document.addEventListener('click', clickHandler);
  };

  protected createNote = (): void => {
    const now = new Date();
    const date = `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).substr(-2)}-${('0' + now.getDate()).substr(-2)}`;
    const [pitch, yaw] = this.state.contextMenuOpen.coords;

    this.setState({
      selectedNote: {
        // Hotspot
        type: 'info',
        pitch,
        yaw,
        cssClass: 'hotspot info',

        // Note
        title: '',
        note: '',
        date,
        user: '',
        x: 0,
        y: 0,
        z: 0,
      }
    });
  };

  protected saveNote = (note: INote): void => {
    if (!note.id) {
      const id = `note${Date.now().toString()}`;

      const newNote = {
        ...note,
        id,
        noteId: id,
        clickHandlerFunc: this.noteHandler,
      };

      Object.assign(newNote, {
        clickHandlerArgs: newNote
      });

      this.config.notes.push(newNote);
      this.viewer.addHotSpot(newNote);

      // TODO Is it possible to add the same note to all scenes ***in the correct relapositions***?
    }
  };

  protected deleteNote = (note: INote): void => {
    if (note.id) {
      this.config.scanLocations.forEach((scanLocation, index) => {
        this.viewer.removeHotSpot(note.id, index.toString());
      });
    }
  };

  protected editTitle = (): void => {
    const title = prompt('Scan name', this.state.title);

    if (title) {
      this.setState({ title });
    }
  };

  protected fadeScene = (fadeImg: HTMLCanvasElement, sceneFadeDuration: number): void => {
    if (this.#fadeTransformOrigin) {
      const transformOrigin = `${this.#fadeTransformOrigin.x}px ${this.#fadeTransformOrigin.y - 48}px`;

      fadeImg.style.transition = `opacity ${sceneFadeDuration}ms ease-in, transform ${sceneFadeDuration}ms ease-in-out`;
      fadeImg.style.opacity = '0';
      fadeImg.style.transformOrigin = transformOrigin;
      fadeImg.style.transform = `scale(${this.#fadeTransformOrigin.z / 3})`;
    }

    this.#fadeTransformOrigin = undefined;
  };
}
