import { PureComponent, ReactNode } from 'react';
import { ContilioLogo } from './components/ContilioLogo';
import './HeaderView.scss';
import { Icon } from '@mdi/react';
import { mdiAccount, mdiChevronRight, mdiFullscreen, mdiFullscreenExit, mdiLogout } from '@mdi/js';
import { Dropdown } from 'semantic-ui-react';
import { eventBus } from '../context/Contilio360Context';
import { ConfigModel } from '../models/ConfigModel';
import { ConfigEvent } from '../events/ConfigEvent';

/**
 * Web application header
 * @author  Neil Rackett
 */
export class HeaderView extends PureComponent<any, any> {
  protected config!: ConfigModel; // Injected

  constructor(props: any) {
    super(props);
    eventBus.inject(this);
    this.state = {
      isFullscreen: false,
      company: '',
      project: '',
      floorNumber: 0,
      scanNumber: 0,
    };
  }

  public componentDidMount(): void {
    document.addEventListener('fullscreenchange', this.fullscreenChangeHandler);
    this.config.addEventListener(ConfigEvent.SCAN_LOCATION_CHANGE, this.refresh);
    this.refresh();
  }

  public render(): ReactNode {
    const fullscreenIconPath = document.fullscreenElement ? mdiFullscreenExit : mdiFullscreen;

    return (
      <header className="HeaderView">
        <ContilioLogo />
        <span className="breadcrumbs">
          <span className="vertical-divider" />
          {this.state.company}
          <Icon path={mdiChevronRight} size={1} color="white" />
          {this.state.project}
          <Icon path={mdiChevronRight} size={1} color="white" />
          Floor {this.state.floorNumber}
        </span>
        <span className="actions">
          <Dropdown trigger={<span><Icon path={mdiAccount} size={1.5} color="white" /></span>}
            pointing="top right"
            icon={null}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.signOutHandler}>
                <Icon path={mdiLogout} size={1} />&nbsp;Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span onClick={this.toggleFullScreen}>
            <Icon path={fullscreenIconPath} size={1.5} color='white' />
          </span>
        </span>
      </header>
    );
  }

  protected toggleFullScreen = (): void => {
    document.fullscreenElement
      ? document.exitFullscreen()
      : document.body.requestFullscreen()
      ;
  };

  protected fullscreenChangeHandler = (event?: Event): void => {
    const isFullscreen = !!document.fullscreenElement;
    this.setState({ isFullscreen });
  };

  protected refresh = (): void => {
    this.setState({
      company: this.config.company,
      project: this.config.project,
      floorNumber: this.config.currentFloor,
      scanNumber: this.config.currentScanLocationIndex + 1
    });
  };

  protected signOutHandler = (): void => {
    localStorage.clear();
    window.location.reload();
  };
}
