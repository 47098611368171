import { Color } from "three";
import { ColorUtils } from "../utils/ColorUtils";
import { PointerState } from "../enums/PointerState";

/**
 * Result status enumerator
 * @author  Neil Rackett
 */
export enum ResultStatus {
  OK = 0,
  DELAY = 2,
  OUT_OF_TOLERANCE = 3,
  NOT_ANALYSED = 7,
  OTHER = 6,
}

export const ResultStatusColor = {
  [ResultStatus.OK]: 0xB7B7B7,
  [ResultStatus.DELAY]: 0xB33636,
  [ResultStatus.OUT_OF_TOLERANCE]: 0xB3B336,
  [ResultStatus.NOT_ANALYSED]: 0x5E80A3,
  [ResultStatus.OTHER]: 0xFFB736,
};

export const ResultStatusHoverColor = {
  [ResultStatus.OK]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.OK], 0.15),
  [ResultStatus.DELAY]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.DELAY], 0.15),
  [ResultStatus.OUT_OF_TOLERANCE]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.OUT_OF_TOLERANCE], 0.15),
  [ResultStatus.NOT_ANALYSED]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.NOT_ANALYSED], 0.15),
  [ResultStatus.OTHER]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.OTHER], 0.15),
};

export const ResultStatusSelectColor = {
  [ResultStatus.OK]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.OK], 0.30),
  [ResultStatus.DELAY]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.DELAY], 0.30),
  [ResultStatus.OUT_OF_TOLERANCE]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.OUT_OF_TOLERANCE], 0.30),
  [ResultStatus.NOT_ANALYSED]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.NOT_ANALYSED], 0.30),
  [ResultStatus.OTHER]: ColorUtils.adjustBrightness(ResultStatusColor[ResultStatus.OTHER], 0.30),
};

export function getResultStatusColor(status: ResultStatus, pointerState: PointerState = PointerState.NONE): Color {
  const palettes = {
    [PointerState.NONE]: ResultStatusColor,
    [PointerState.HOVER]: ResultStatusHoverColor,
    [PointerState.SELECT]: ResultStatusSelectColor
  };

  const palette = palettes[pointerState];

  return new Color(palette[status]);
}

/**
 * Result status options, formatted for use in Semantic UI Select and Dropdown coponents,
 * and anywhere else we need a list of statuses
 *
 * @author  Neil Rackett
 */
export class ResultStatusOptions {
  public static readonly OPTIONS: IResultStatusOption[] = [
    { key: ResultStatus.OK, value: ResultStatus.OK, text: 'Built Correctly', visible: true },
    { key: ResultStatus.DELAY, value: ResultStatus.DELAY, text: 'Behind Schedule', visible: true },
    { key: ResultStatus.OUT_OF_TOLERANCE, value: ResultStatus.OUT_OF_TOLERANCE, text: 'Out of Tolerance', visible: true },
    { key: ResultStatus.NOT_ANALYSED, value: ResultStatus.NOT_ANALYSED, text: 'Not Analysed', visible: true },
    { key: ResultStatus.OTHER, value: ResultStatus.OTHER, text: 'Other', visible: true },
  ];
};

export interface IResultStatusOption {
  key: number,
  value: number,
  text: string,
  visible: boolean,
}
