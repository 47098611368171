import { ConbineEvent } from 'conbine';

/**
 * BIM event
 * @author  Neil Rackett
 */
export class BimEvent extends ConbineEvent {
  public static readonly BIM_COMPLETE: string = "bimComplete";
  public static readonly BIM_HIDDEN_ELEMENTS_CHANGE = "bimHiddenMeshesChange";
  public static readonly BIM_SELECT: string = "bimSelect";
  public static readonly BIM_INTERSECTION: string = "bimIntersection";
  public static readonly BIM_INTERSECTION_SELECT: string = "bimIntersectionSelect";
  public static readonly BIM_LOAD_START: string = "bimLoadStart";
  public static readonly BIM_LOAD_PROGRESS: string = "bimLoadProgress";
  public static readonly BIM_LOAD_END: string = "bimLoadEnd";
  public static readonly REQUEST_FIT_BIM_TO_VIEWER: string = "requestFitBimToViewer";

  public flag: boolean;

  constructor(type: string, data?: any, flag: boolean = false) {
    super(type, data);
    this.flag = flag;
  }
}
