import { DoubleSide, MeshPhongMaterial, MeshPhongMaterialParameters } from "three";

const defaultParameters: BimMaterialParameters = {
  shininess: 0,
  side: DoubleSide,
  transparent: false,
  vertexColors: true,
};

export interface BimMaterialParameters extends MeshPhongMaterialParameters {
  // TODO Add custom parameters here
}

/**
 * Material for use by BIM loaders and renderers
 * @author  Neil Rackett
 */
export class BimMaterial extends MeshPhongMaterial {
  constructor(parameters?: BimMaterialParameters) {
    super({ ...defaultParameters, ...parameters });
  }
}
