import { Context } from "conbine";
import { RequestScanLocationCommand } from "../commands/RequestScanLocationCommand";
import { StartupCommand } from "../commands/StartupCommand";
import { Contilio360Event } from "../events/Contilio360Event";
import { ConfigModel } from "../models/ConfigModel";
import { ViewSyncModel } from "../models/ViewSyncModel";

export class Contilio360Context extends Context {
  constructor() {
    super();

    this
      .mapSingleton('config', ConfigModel)
      .mapSingleton('viewSync', ViewSyncModel)
      ;

    this
      .mapCommand(Contilio360Event.STARTUP, StartupCommand)
      .mapCommand(Contilio360Event.REQUEST_SCAN_LOCATION, RequestScanLocationCommand)
      ;
  }
}

export const eventBus = new Contilio360Context();
