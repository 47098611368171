import { Command } from "conbine";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "../App";
import { Contilio360Provider } from "../context";
import { eventBus } from "../context/Contilio360Context";
import { AssetUrl } from "../enums/AssetUrl";
import { ConfigModel } from "../models/ConfigModel";

export class StartupCommand extends Command {
  protected config!: ConfigModel; // Injected

  public async execute(): Promise<void> {
    eventBus.inject(this, 'config');

    if ('serviceWorker' in navigator) {
      navigator
        .serviceWorker
        .register(`${process.env.PUBLIC_URL}/serviceWorker.js`)
        .then(registration => {
          try {
            registration.update();
          } catch (e) {
            // Not currently supported by Safari on iOS, so ignore it
          }
        })
        .catch(error => {
          console.warn('ServiceWorker registration failed:', error);
        });
    }

    const request = await fetch(`${AssetUrl.S3_URI}/config.json`);
    const config = await request.json();

    this.config.set(config);

    ReactDOM.render(
      <Contilio360Provider>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </Contilio360Provider>,
      document.getElementById('root')
    );
  }
}