import 'pannellum/src/js/libpannellum';
import 'pannellum/src/js/pannellum';
import 'pannellum/src/css/pannellum.css';
import { BufferGeometry, Mesh } from 'three';
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from 'three-mesh-bvh/build/index.module.js';
import { eventBus } from './context/Contilio360Context';
import { Contilio360Event } from './events/Contilio360Event';
import './index.scss';
import reportWebVitals from './reportWebVitals';

(BufferGeometry.prototype as any).computeBoundsTree = computeBoundsTree;
(BufferGeometry.prototype as any).disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

eventBus.dispatchEvent(new Contilio360Event(Contilio360Event.STARTUP));

reportWebVitals();
