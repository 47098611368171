import { PureComponent, ReactNode } from 'react';
import './FooterView.scss';

/**
 * Web application footer
 * @author  Neil Rackett
 */
export class FooterView extends PureComponent<any, any> {
  public render(): ReactNode {
    return (
      <footer className="FooterView">
        <p>
          Copyright © Contilio Limited 2021. All rights reserved.
          &nbsp;|&nbsp; Terms of Use
          &nbsp;|&nbsp; Privacy Notice
        </p>
      </footer>
    );
  }
}