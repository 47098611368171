import React from 'react';
import './ContilioLogo.scss';

interface IContilioLogoParams {
  logoWidth?: number,
  logoColor?: string,
}

export const ContilioLogo = (params: IContilioLogoParams) => (
  <div className="ContilioLogo">
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.35 37.55"
      width={params.logoWidth || 30}
      fill={params.logoColor || 'white'}>
      <g>
        <g>
          <path d="M13.63,15.94V20l-3.37-1.95L6.89,16.14l3.37-1.94,3.37-1.95,1.41.81a2.11,2.11,0,0,0-1.35,2Z" />
          <path d="M20.38,8.36V13a1.51,1.51,0,0,0-.3,0H15.79a2.08,2.08,0,0,0-.75.13l-1.41-.81L17,10.3Z" />
          <polygon points="6.94 8.39 6.94 12.28 6.94 16.17 3.57 14.23 0.2 12.28 3.57 10.33 6.94 8.39" />
          <polygon points="13.68 12.28 17.05 10.33 20.38 8.42 20.38 8.36 17.05 6.44 13.68 4.49 13.68 8.39 13.68 12.28" />
          <polygon points="13.68 4.49 13.68 8.39 13.68 12.28 10.31 10.33 6.94 8.39 10.31 6.44 13.68 4.49" />
          <polygon points="6.94 16.17 10.31 14.23 13.68 12.28 10.31 10.33 6.94 8.39 6.94 12.28 6.94 16.17" />
          <polygon points="21.73 25.76 18.26 23.79 14.79 21.81 14.79 25.76 14.79 29.71 18.26 27.74 21.73 25.76" />
          <polygon points="21.64 33.6 18.21 31.63 14.79 29.65 14.79 33.6 14.79 37.55 18.21 35.58 21.64 33.6" />
          <polygon points="28.48 29.65 25.06 27.68 21.64 25.7 21.64 29.65 21.64 33.6 25.06 31.63 28.48 29.65" />
          <polygon points="14.79 29.65 18.21 27.68 21.64 25.7 21.64 29.65 21.64 33.6 18.21 31.63 14.79 29.65" />
          <polygon points="6.79 25.31 10.21 23.33 13.63 21.36 10.21 19.38 6.79 17.41 6.79 21.36 6.79 25.31" />
          <polygon points="0 21.39 3.42 19.41 6.84 17.44 3.42 15.46 0 13.49 0 17.44 0 21.39" />
          <polygon points="6.79 17.47 6.79 21.39 6.79 25.31 3.4 23.35 0 21.39 3.4 19.43 6.79 17.47" />
          <polygon points="0 29.23 3.4 27.27 6.79 25.31 3.4 23.35 0 21.39 0 25.31 0 29.23" />
          <polygon points="6.79 25.31 10.21 23.33 13.63 21.36 13.63 25.31 13.63 29.26 10.21 27.28 6.79 25.31" />
          <polygon points="6.79 33.15 10.21 31.18 13.63 29.2 13.63 33.15 13.63 37.1 10.21 35.13 6.79 33.15" />
          <polygon points="13.58 29.23 10.19 27.27 6.79 25.31 6.79 29.23 6.79 33.15 10.19 31.19 13.58 29.23" />
          <polygon points="0 29.23 3.4 27.27 6.79 25.31 6.79 29.23 6.79 33.15 3.4 31.19 0 29.23" />
          <rect x="27.68" y="4.9" width="2.39" height="2.2" rx="0.38" ry="0.38" />
          <rect x="29.6" y="14.76" width="3.13" height="2.88" rx="0.5" ry="0.5" />
          <rect x="17.91" y="2.67" width="2.65" height="2.44" rx="0.42" ry="0.42" />
          <rect x="24.45" width="1.41" height="1.3" rx="0.22" ry="0.22" />
          <rect x="14.79" y="14.03" width="6.28" height="5.77" rx="1" ry="1" />
          <rect x="23.74" y="20.92" width="4.59" height="4.22" rx="0.73" ry="0.73" />
          <rect x="21.85" y="8.59" width="3.86" height="3.55" rx="0.61" ry="0.61" />
          <rect x="34.04" y="9.8" width="1.31" height="1.21" rx="0.21" ry="0.21" />
          <rect x="32.91" y="1.09" width="1.31" height="1.21" rx="0.21" ry="0.21" />
        </g>
      </g>
    </svg>
    Contilio
  </div>
);