import { IResult } from '../models/IResult';
import * as Papa from 'papaparse';

/**
 * Loads and parses the results data (csvData.csv) for use by the 3D model
 * @author  Neil Rackett
 */
export class ResultsLoader {
  public async load(url: string): Promise<IResult[]> {
    const request = await fetch(url);

    if (request) {
      const csvText = await request.text();
      let { data: results } = await this.parseCsv(csvText);

      results.forEach((result: any, index: number) => {
        result.threshold = ~~result['threshold (mm)'];
        result.oot = ~~result['oot value (mm)'] || ~~result['oot amount (mm)'];
        result.status = ~~result.res;
        result.pointSourceID = index + 1; // pointSourceID value is assigned to each element in point cloud
      });

      results = results.filter((row: any) => !!row?.guid);

      return results as IResult[];
    }

    return [];
  }

  public parseCsv = async (csv: string) => {
    const csvJson = Papa.parse(csv, { delimiter: '', header: true });
    return csvJson;
  };
}
