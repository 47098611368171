import { Matrix4 } from "three";

/**
 * Matrix used to correctly orient Contilio BIM and point cloud in Three.js
 * @author  Neil Rackett
 */
export const orientationMatrix4 = new Matrix4().set(
  1, 0, 0, 0,
  0, 0, 1, 0,
  0, -1, 0, 0,
  0, 0, 0, 1
);

export default orientationMatrix4;
