import { EventDispatcher } from 'conbine';
import { unzipSync } from 'fflate';
import { BufferAttribute, BufferGeometry, Color, Group, Mesh } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { getResultStatusColor, ResultStatus } from '../enums/ResultStatus';
import orientationMatrix4 from '../geom/orientationMatrix4';
import { BimMaterial } from '../materials/BimMaterial';
import { IResult } from '../models/IResult';

export enum BimLoaderColorMode {
  NONE = 0,
  STATUS = 1,
  CLUSTER = 2,
}

/**
 * Loads BIM and merges elements into clusters to improve performance
 * @author  Neil Rackett
 */
export class BimLoader extends EventDispatcher {

  public colorMode: BimLoaderColorMode = BimLoaderColorMode.STATUS;
  public maxClusterSize: number = 256;

  #abortControllers: AbortController[] = [];

  /**
   * Loads all of the elements from a zip file
   */
  public async load(url: string, results: IResult[] = []): Promise<{ group: Group, geometries: BufferGeometry[], meshes: Mesh[]; }> {
    const abortController = new AbortController();
    const { signal } = abortController;

    this.#abortControllers.push(abortController);

    const zipRequest = await fetch(url, { signal });

    this.#abortControllers.splice(this.#abortControllers.indexOf(abortController), 1);

    const zipBuffer = await zipRequest.arrayBuffer();
    const zipArray = new Uint8Array(zipBuffer);

    const group = new Group();
    const geometries: BufferGeometry[] = [];
    const meshes: Mesh[] = [];

    let filteredGeometries: BufferGeometry[] = []; // Geometries with results
    let unfilteredGeometries: BufferGeometry[] = []; // Geometries without results

    const stlLoader = new STLLoader();
    const files = unzipSync(zipArray);

    const mergeFilteredGeometries = (geometries?: BufferGeometry[]): void => {
      const mesh = this.toMesh(geometries || filteredGeometries);
      if (mesh) {
        meshes.push(mesh);
        group.add(mesh);
      }
      filteredGeometries = [];
    };

    const filenames: string[] = Object.keys(files).filter(key => /\.stl$/.test(key));

    for (const filename of filenames) {
      const result: IResult | null = results.find(result => filename.includes(result.guid)) || null;
      const file = files[filename];
      const stl = file?.buffer;

      if (stl) {
        const geometry = stlLoader.parse(stl);
        const numVertex = geometry.getAttribute('position').count;

        geometry.userData = result || {};

        // Colour

        let color: Color;

        switch (this.colorMode) {
          case BimLoaderColorMode.NONE:
            color = getResultStatusColor(ResultStatus.OK);
            break;
          case BimLoaderColorMode.CLUSTER:
            color = new Color(0xFF0000 + Math.floor((result?.pointSourceID || 0) / this.maxClusterSize) * 8192);
            break;
          case BimLoaderColorMode.STATUS:
          default:
            color = getResultStatusColor(result?.status || ResultStatus.OK);
            break;
        }

        const rgb = color.toArray().map((value: number) => value * 255);
        const itemSize = 3;  // r, g, b
        const colors = new Uint8Array(itemSize * numVertex);

        colors.forEach((value, index) => colors[index] = rgb[index % 3]);

        const normalized = true;
        const colorAttr = new BufferAttribute(colors, itemSize, normalized);

        geometry.setAttribute('color', colorAttr);

        if (result?.guid) {
          // GUID referenced using pointSourceID for consistency with Potree
          const pointSourceIds = new Uint16Array(numVertex);
          pointSourceIds.forEach((value, index) => pointSourceIds[index] = result?.pointSourceID);

          const pointSourceIdAttr = new BufferAttribute(pointSourceIds, 1, normalized);
          geometry.setAttribute('pointSourceID', pointSourceIdAttr);

          geometries.push(geometry);

          filteredGeometries.push(geometry);
        } else {
          unfilteredGeometries.push(geometry);
        }

        if (filteredGeometries.length && !(geometries.length % this.maxClusterSize)) {
          mergeFilteredGeometries();
        }
      }
    }

    mergeFilteredGeometries();

    if (unfilteredGeometries.length) {
      const mesh = this.toMesh(unfilteredGeometries);
      if (mesh) {
        group.add(mesh);
      }
    }

    group.applyMatrix4(orientationMatrix4);

    return {
      geometries,
      meshes,
      group
    };
  }

  public abort(): void {
    this.#abortControllers.forEach(controller => {
      controller.abort();
    });
    this.#abortControllers = [];
  }

  protected toMesh(geometries: BufferGeometry[]): Mesh | null {
    geometries = (geometries || []).filter(geometry => geometry?.isBufferGeometry);

    if (geometries.length) {
      try {
        const geometry = BufferGeometryUtils.mergeBufferGeometries(geometries, false);
        geometry.userData.results = geometry.userData.mergedUserData;
        geometry.userData.pointSourceIDs = geometry.userData.results.map((result: IResult) => result.pointSourceID);

        // three-mesh-bvh
        (geometry as any).computeBoundsTree({ lazyGeneration: false });

        const material = new BimMaterial();

        const mesh = new Mesh(geometry, material);
        mesh.userData = {
          results: geometry.userData.results,
          pointSourceIDs: geometry.userData.pointSourceIDs,
        };
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        return mesh;
      } catch (e: any) {
        return null;
      }
    }

    return null;
  };

}

export default BimLoader;
