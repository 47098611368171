import { EventDispatcher } from 'conbine';
import { BufferAttribute, BufferGeometry, Camera, Group, Intersection, Mesh, Object3D, Raycaster, Scene, Vector3 } from 'three';
import { PointerState } from '../enums/PointerState';
import { getResultStatusColor, IResultStatusOption, ResultStatus } from '../enums/ResultStatus';
import { ITradeOptions } from '../enums/Trade';
import { BimEvent } from '../events/BimEvent';
import { BimLoader, BimLoaderColorMode } from '../loaders/BimLoader';
import { BimMaterial } from '../materials/BimMaterial';
import { IResult } from '../models/IResult';
import { ArrayUtils } from '../utils/ArrayUtils';
import { isJsonEqual } from '../utils/isEqual';

const HIDE_DISTANCE = 10000;
const HIDE_THRESHOLD = 2000;

/**
 * BIM Renderer for Contilio Web App
 * Can be used to load and render 1 or more BIM
 *
 * @author	Neil Rackett
 */
export class BimRenderer extends EventDispatcher {
  #group: Group = new Group();
  #loader: BimLoader = new BimLoader();

  #bims: Group[] = [];
  #meshes: Mesh[][] = []; // An array of Meshes that make up the BIMs
  #geometries: BufferGeometry[][] = []; // Array of geometies that make up the meshes
  #results: IResult[][] = []; // Array of results associated with the BIMs

  #hoveredPointSourceID: number = 0; // 0 = none
  #selectedPointSourceID: number = 0; // 0 = none
  #allHiddenPointSourceIDs = new Set<number>(); // Including filters
  #userHiddenPointSourceIDs = new Set<number>(); // Using show/hide methods, etc

  #currentIntersection: Intersection | null = null;

  #tradeOptions: ITradeOptions = {};
  #statusOptions: IResultStatusOption[] = [];
  #statusEnabled: boolean = true;
  #opacity: number = 1;

  /**
   * The point source IDs of all elements that have been rendered, regardless of visibility
   */
  private get rendererdPointSourceIDs(): number[] {
    return this._allMeshes.map(mesh => mesh.userData.pointSourceIDs).flat();
  }

  /**
   * The results associated with all elements that have been rendered, regardless of visibility
   */
  private get _renderedResults(): IResult[] {
    return this._allMeshes.map(mesh => mesh.userData.results).flat();
  }

  private get _allGeometries(): BufferGeometry[] {
    return this.#geometries.flat();
  }

  private get _allMeshes(): Mesh[] {
    return this.#meshes.flat();
  }

  private get _allResults(): IResult[] {
    return this.#results.flat();
  }

  // TODO Hide meshes where all contained elements are hidden
  private get _visibleMeshes(): Mesh[] {
    return this._allMeshes.filter(mesh => mesh.visible);
  }

  // Parameters are here in case we want to add an outline pass or similar for rollover in future
  constructor(scene: Scene, camera: Camera) {
    super();
  }

  public get group(): Group {
    return this.#group;
  }

  public get position(): Vector3 {
    return this.group.position;
  }
  public set position(value: Vector3) {
    this.group.position.copy(value);
  }

  public get opacity(): number {
    return this.#opacity;
  }
  public set opacity(value: number) {
    if (value !== this.opacity) {
      this.setOpacity(this.group, value);
      this.#opacity = value;
    }
  }

  protected setOpacity(obj: Object3D, opacity: number): void {
    if (!obj) return;
    const material = (obj as any).material as BimMaterial;
    if (material) {
      if (material && material.opacity !== opacity) {
        material.opacity = opacity;
        material.transparent = opacity < 1;
      }
    }
    obj.children.forEach(child => {
      this.setOpacity(child, opacity);
    });
  };

  /**
   * Visibility of the entire BIM
   */
  public get visible(): boolean {
    return this.group.visible;
  }
  public set visible(value: boolean) {
    if (value !== this.group.visible) {
      this.group.visible = value;
      if (!value) {
        this.deselectAll();
      }
    }
  }

  public get selectedGeometry(): BufferGeometry | undefined {
    return this.getGeometry(this.#selectedPointSourceID);
  }

  public get selectedResult(): IResult | undefined {
    return this.getResult(this.#selectedPointSourceID);
  }

  public get intersection(): Intersection | null {
    return this.#currentIntersection;
  }

  public get tradeOptions(): ITradeOptions {
    return this.#tradeOptions;
  }
  public set tradeOptions(value: ITradeOptions) {
    value || (value = {});
    if (!isJsonEqual(value, this.#tradeOptions)) {
      this.#tradeOptions = value;
      this.updateVisibility();
    }
  }

  public get statusOptions(): IResultStatusOption[] {
    return this.#statusOptions;
  }
  public set statusOptions(value: IResultStatusOption[]) {
    value || (value = []);
    if (!isJsonEqual(value, this.#statusOptions)) {
      this.#statusOptions = value;
      this.updateVisibility();
    }
  }

  public get statusEnabled(): boolean {
    return this.#statusEnabled;
  }
  public set statusEnabled(value: boolean) {
    if (value !== this.#statusEnabled) {
      this.#statusEnabled = value;
      this.updateStatus();
    }
  }

  public async load(url: string, results: IResult[] = []): Promise<Group> {
    this.#loader.colorMode = this.statusEnabled ? BimLoaderColorMode.STATUS : BimLoaderColorMode.NONE;
    const { group, geometries, meshes } = await this.#loader.load(url, results);

    group.userData.bimIndex = this.#bims.length;

    this.group.add(group);

    this.#results.push(results);
    this.#bims.push(group);
    this.#meshes.push(meshes);
    this.#geometries.push(geometries);

    return group;
  }

  public unload() {
    this.#loader.abort();

    this.#userHiddenPointSourceIDs.clear();
    this.#allHiddenPointSourceIDs.clear();
    this.#hoveredPointSourceID = 0;
    this.#selectedPointSourceID = 0;

    this.#meshes = [];
    this.#geometries = [];
    this.#results = [];

    this.dispatchHiddenElementsChange();
    this.dispatchSelect();

    while (this.#bims.length) {
      const bimGroup = this.#bims.pop();
      if (bimGroup) {
        bimGroup.removeFromParent();
        bimGroup.clear();
      }
    }

    this.#meshes = [];
  }

  public update(raycaster: Raycaster) {
    if (!this.visible) {
      this.#hoveredPointSourceID = 0;
      this.#currentIntersection = null;
      return;
    }

    const intersections = raycaster.intersectObjects(this._visibleMeshes);
    const pointSourceID = this.intersectionToPointSourceID(intersections[0]);

    if (pointSourceID !== this.#hoveredPointSourceID && this.#hoveredPointSourceID !== this.#selectedPointSourceID) {
      this.setElementColor(this.#hoveredPointSourceID, PointerState.NONE);
    }

    if (pointSourceID !== this.#selectedPointSourceID) {
      this.setElementColor(pointSourceID, PointerState.HOVER);
    }

    if (pointSourceID !== this.#hoveredPointSourceID) {
      this.#hoveredPointSourceID = pointSourceID;
    }

    this.#currentIntersection = intersections[0];

    const event = new BimEvent(BimEvent.BIM_INTERSECTION, this.#currentIntersection);
    this.dispatchEvent(event);
  }

  public pick(raycaster: Raycaster): IResult | undefined {
    const intersections = raycaster.intersectObjects(this._visibleMeshes);
    const pointSourceID = this.intersectionToPointSourceID(intersections[0]);

    if (pointSourceID !== this.#selectedPointSourceID) {
      this.setElementColor(this.#selectedPointSourceID, PointerState.NONE);
      this.setElementColor(pointSourceID, PointerState.SELECT);

      this.#selectedPointSourceID = pointSourceID;
      this.dispatchSelect();
    }

    // Needs to be dispatched every time for measurement tools, etc
    const event = new BimEvent(BimEvent.BIM_INTERSECTION_SELECT, this.intersection);
    this.dispatchEvent(event);

    return this.selectedResult;
  }

  public setSize(width: number, height: number) {
    // TODO This is here to allow for feature such as outline pass on rollover in future
  }

  /**
   * Selects the named object, if specified, otherwise the currently intersected object, if any
   * @returns The result object from the selected object or null
   */
  public select(resultOrGuid: IResult | string): IResult | undefined {
    const pointSourceID = this.getPointSourceID(resultOrGuid);

    if (pointSourceID !== this.#selectedPointSourceID) {
      this.setElementColor(this.#selectedPointSourceID, PointerState.NONE);
      this.setElementColor(pointSourceID, PointerState.SELECT);

      this.#selectedPointSourceID = pointSourceID;
    }

    this.dispatchSelect();

    if (this.#selectedPointSourceID === this.#hoveredPointSourceID) {
      const event = new BimEvent(BimEvent.BIM_INTERSECTION_SELECT, this.intersection);
      this.dispatchEvent(event);
    }

    return this.selectedResult;
  }

  public deselectAll(): void {
    if (this.#selectedPointSourceID) {
      this.setElementColor(this.#selectedPointSourceID, PointerState.NONE);
      this.#selectedPointSourceID = 0;
      this.dispatchSelect();
    }
  }

  /**
   * Hides the specified element or currently seleted element if no element specified
   */
  public hide(resultOrGuid?: IResult | string): IResult | undefined {
    const pointSourceID = this.getPointSourceID(resultOrGuid) || this.#selectedPointSourceID;

    if (pointSourceID && !this.#userHiddenPointSourceIDs.has(pointSourceID)) {
      this.#userHiddenPointSourceIDs.add(pointSourceID);
      this.updateVisibility();

      if (pointSourceID === this.#hoveredPointSourceID) {
        this.setElementColor(this.#hoveredPointSourceID, PointerState.NONE);
      }
      if (pointSourceID === this.#selectedPointSourceID) {
        this.setElementColor(this.#selectedPointSourceID, PointerState.NONE);
        this.#selectedPointSourceID = 0;
        this.dispatchSelect();
      }
    }

    return this.getResult(pointSourceID);
  }

  public hideAllExcept(resultOrGuid?: IResult | string): IResult | undefined {
    const pointSourceID = this.getPointSourceID(resultOrGuid) || this.#selectedPointSourceID;
    const allOtherPointSourceIDs = this.rendererdPointSourceIDs.filter(id => id !== pointSourceID);

    if (allOtherPointSourceIDs.includes(this.#hoveredPointSourceID)) {
      this.setElementColor(this.#hoveredPointSourceID, PointerState.NONE);
    }
    if (allOtherPointSourceIDs.includes(this.#selectedPointSourceID)) {
      this.setElementColor(this.#selectedPointSourceID, PointerState.NONE);
    }
    allOtherPointSourceIDs.forEach(this.#userHiddenPointSourceIDs.add, this.#userHiddenPointSourceIDs);

    this.#userHiddenPointSourceIDs = new Set<number>(allOtherPointSourceIDs);
    this.updateVisibility();

    return this.getResult(pointSourceID);
  }

  public setHiddenElements(results: IResult[], silent: boolean = false): void {
    const pointSourceIDs = results.map(result => result.pointSourceID);

    if (pointSourceIDs.includes(this.#hoveredPointSourceID)) {
      this.setElementColor(this.#hoveredPointSourceID, PointerState.NONE);
    }
    if (pointSourceIDs.includes(this.#selectedPointSourceID)) {
      this.setElementColor(this.#selectedPointSourceID, PointerState.NONE);
    }

    this.#userHiddenPointSourceIDs = new Set<number>(pointSourceIDs);
    this.updateVisibility(silent);
  }

  /**
   * Show an object that has been individually hidden
   */
  public show(resultOrGuid: IResult | string): IResult | undefined {
    const pointSourceID = this.getPointSourceID(resultOrGuid) || this.#selectedPointSourceID;

    if (pointSourceID && this.#userHiddenPointSourceIDs.has(pointSourceID)) {
      this.#userHiddenPointSourceIDs.delete(pointSourceID);
      this.updateVisibility();
    }

    return this.getResult(pointSourceID);
  };

  /**
   * Show all the objects that have been individually hidden
   */
  public showAll(): void {
    this.#userHiddenPointSourceIDs.clear();
    this.updateVisibility();
  }

  /* Internal methods */

  protected setElementColor(pointSourceID: number, pointerState: PointerState = PointerState.NONE): void {
    if (pointSourceID) {
      const pointSourceIndices = this.getPointSourceIndices(pointSourceID);

      if (pointSourceIndices) {
        const geometry = this.findGeometryIncludes(pointSourceID);

        if (geometry) {
          const result = this.getResult(pointSourceID);
          const color = this.#statusEnabled
            ? getResultStatusColor(result?.status || ResultStatus.OK, pointerState)
            : getResultStatusColor(ResultStatus.OK, pointerState);
          const colorAttr = geometry.getAttribute('color') as BufferAttribute;
          const colorRgb = color.toArray().map(value => value * 255);

          for (let i = 0; i < pointSourceIndices.length; i++) {
            const colorIndex = pointSourceIndices[i] * colorAttr.itemSize;

            for (let c = 0; c < colorAttr.itemSize; c++) {
              (colorAttr.array as Uint8Array)[colorIndex + c] = colorRgb[c];
            }
          }

          colorAttr.needsUpdate = true;
        }
      }
    }
  }

  protected updateVisibility(silent: boolean = false): void {
    const currentHiddenPointSourceIDs = new Set<number>(this.#allHiddenPointSourceIDs);
    const currentVisiblePointSourceIDs = new Set<number>(this.rendererdPointSourceIDs.filter(i => !currentHiddenPointSourceIDs.has(i)));

    const { tradeOptions } = this;
    const hiddenStatuses = new Set<number>(this.statusOptions.filter(option => !option.visible).map(option => option.key));

    const hiddenResults = this._renderedResults.filter(result => {
      return (
        // Hidden by user
        this.#userHiddenPointSourceIDs.has(result.pointSourceID)
        || // Hidden by trade
        !tradeOptions[result.trade]
        || // Hidden by status
        hiddenStatuses.has(result.status)
      );
    });

    const updatedHiddenPointSourceIDs = new Set<number>(hiddenResults.map(result => result.pointSourceID));
    const updatedVisiblePointSourceIDs = new Set<number>(this.rendererdPointSourceIDs.filter(i => !updatedHiddenPointSourceIDs.has(i)));

    let toHide = new Set([...updatedHiddenPointSourceIDs].filter(i => !currentHiddenPointSourceIDs.has(i)));
    this.setElementVisibility(false, ...toHide);

    let toShow = new Set([...updatedVisiblePointSourceIDs].filter(i => !currentVisiblePointSourceIDs.has(i)));
    this.setElementVisibility(true, ...toShow);

    this.#allHiddenPointSourceIDs = updatedHiddenPointSourceIDs;

    if (toHide.size || toShow.size) {
      this.dispatchHiddenElementsChange(silent);
    }
  }

  protected getObjectsByName(name: string): Object3D[] {
    const objects: Object3D[] = [];
    this.group.traverse(object => {
      if (object.name === name) {
        objects.push(object);
      }
    });
    return objects;
  }

  protected intersectionToPointSourceID(intersection: Intersection): number {
    if (intersection) {
      const { geometry } = intersection.object as Mesh;
      const attrIndex = intersection.face?.a;

      if (attrIndex) {
        const pointSourceIdAttr = geometry.getAttribute('pointSourceID') as BufferAttribute;
        return pointSourceIdAttr.array[attrIndex];
      }
    }

    return 0;
  }

  protected getPointSourceID(resultOrGuid: IResult | string | undefined): number {
    const result = typeof resultOrGuid === 'string'
      ? this._allResults.find(result => result.guid === resultOrGuid)
      : resultOrGuid;

    const p = result?.pointSourceID || 0;

    return ~~p;
  }

  protected getGeometry(pointSourceID: number): BufferGeometry | undefined {
    return this._allGeometries.find(geometry => geometry.userData.pointSourceID === pointSourceID);
  }

  protected getResult(pointSourceID: number): IResult | undefined {
    return this._allResults.find(result => result.pointSourceID === pointSourceID);
  }

  protected findMeshIncludes(pointSourceID: number): Mesh | undefined {
    return this._allMeshes.find(mesh => mesh.userData.pointSourceIDs.includes(pointSourceID));
  }

  protected findGeometryIncludes(pointSourceID: number): BufferGeometry | undefined {
    return this.findMeshIncludes(pointSourceID)?.geometry;
  }

  protected getPointSourceIndices(pointSourceID: number): Uint32Array | null {
    const geometry = this.findGeometryIncludes(pointSourceID);

    if (geometry) {
      const pointSourceIdAttr = geometry.getAttribute('pointSourceID') as BufferAttribute;
      const pointSourceIndices: number[] = [];

      (pointSourceIdAttr.array as Uint32Array).forEach((value: number, index: number) => {
        if (value === pointSourceID) {
          pointSourceIndices.push(index);
        }
      });

      return Uint32Array.from(pointSourceIndices);
    }

    return null;
  }

  /**
   * Set the visibility of one or more elements
   */
  protected setElementVisibility(visible: boolean, ...pointSourceIDs: number[]): void {
    if (pointSourceIDs.length === 1) {
      // this is up to 100x faster that the version below for single elements

      const pointSourceID = pointSourceIDs[0];
      const pointSourceIndices = this.getPointSourceIndices(pointSourceID);
      const numPointSourceIndices = pointSourceIndices?.length;

      if (numPointSourceIndices) {
        const geometry = this.findGeometryIncludes(pointSourceID);
        const positionAttr = geometry?.getAttribute('position') as BufferAttribute;
        const positions = positionAttr.array as Float32Array;

        for (let k = 0; k < numPointSourceIndices; k++) {
          const positionIndex = pointSourceIndices[k] * positionAttr.itemSize;
          const position = Math.abs(positions[positionIndex]);

          // Only show/hide is element is not already shown/hidden
          if ((visible && position > HIDE_THRESHOLD) || (!visible && position < HIDE_THRESHOLD)) {
            positions[positionIndex] += HIDE_DISTANCE * (visible ? 1 : -1);
          }
        }

        positionAttr.needsUpdate = true;
      }
    } else if (pointSourceIDs.length) {
      // this is up to 100x faster that iterating using the version above for multiple elements

      const meshes = this._allMeshes;
      const geometries = meshes.map(mesh => mesh.geometry);
      const numGeometries = geometries.length;

      for (let i = 0; i < numGeometries; i++) {
        const geometry = geometries[i];
        const pointSourceIndices: number[] = [];

        // eslint-disable-next-line
        {
          const pointSourceIdAttr = geometry.getAttribute('pointSourceID') as BufferAttribute;
          const geometryPointSourceIDs = new Set<number>(pointSourceIDs.filter(id => ArrayUtils.includes(geometry.userData.pointSourceIDs, id)));
          const pointSourceIdAttrs = pointSourceIdAttr.array as Uint32Array;
          const numPointSourceIdAttrs = pointSourceIdAttrs.length;

          // 30% faster than Array.forEach
          for (let j = 0; j < numPointSourceIdAttrs; j++) {
            if (geometryPointSourceIDs.has(pointSourceIdAttrs[j])) {
              pointSourceIndices.push(j);
            }
          }
        }

        const numPointSourceIndices = pointSourceIndices.length;

        // eslint-disable-next-line
        {
          if (numPointSourceIndices) {
            const positionAttr = geometry.getAttribute('position') as BufferAttribute;
            const positions = positionAttr.array as Float32Array;

            for (let k = 0; k < numPointSourceIndices; k++) {
              const positionIndex = pointSourceIndices[k] * positionAttr.itemSize;
              const position = Math.abs(positions[positionIndex]);

              // Only show/hide is element is not already shown/hidden
              if ((visible && position > HIDE_THRESHOLD) || (!visible && position < HIDE_THRESHOLD)) {
                positions[positionIndex] += HIDE_DISTANCE * (visible ? 1 : -1);
              }
            }

            positionAttr.needsUpdate = true;
          }
        }
      }
    }
  }

  protected dispatchHiddenElementsChange(silent: boolean = false): void {
    const event = new BimEvent(
      BimEvent.BIM_HIDDEN_ELEMENTS_CHANGE,
      this._renderedResults.filter(result => this.#userHiddenPointSourceIDs.has(result.pointSourceID)),
      silent
    );

    this.dispatchEvent(event);
  }

  protected dispatchSelect(): void {
    const event = new BimEvent(BimEvent.BIM_SELECT, this.getResult(this.#selectedPointSourceID));
    this.dispatchEvent(event);
  }

  protected updateStatus(): void {
    const results = this.#results.flat();

    results.forEach(result => {
      if (result.status !== ResultStatus.OK) {
        this.setElementColor(result.pointSourceID, PointerState.NONE);
      }
    });

    // this.updateVisibility();
  }

}

export default BimRenderer;
