import { mdiCalendar, mdiMapMarkerLeft, mdiMapMarkerRight } from '@mdi/js';
import Icon from '@mdi/react';
import React, { forwardRef, PureComponent, ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Popup } from 'semantic-ui-react';
import { eventBus } from '../../context/Contilio360Context';
import { Contilio360Event } from '../../events/Contilio360Event';
import { ConfigModel } from '../../models/ConfigModel';
import { ViewSyncModel } from '../../models/ViewSyncModel';
import './DateInput.scss';

/**
 * Date and scan selector
 * @author  Neil Rackett
 */
export class DateInput extends PureComponent<any, any> {
  protected config!: ConfigModel; // Injected
  protected viewSync!: ViewSyncModel; // Injected

  constructor(props: any) {
    super(props);
    this.state = {
      reportDate: null,
    };
  }

  public componentDidMount(): void {
    eventBus.inject(this);
    this.setState({ reportDate: this.config.reportDate });
  }

  public componentWillUnmount(): void {
    eventBus.uninject(this);
  }

  public render(): ReactNode {
    const classList = ['DateInput'];

    if (this.props.className) classList.push(this.props.className);
    if (this.props.compact) classList.push('compact');

    return (
      <div className={classList.join(' ')}>
        <Popup content="Earlier scan position" inverted position="top center" mouseEnterDelay={1000} trigger={
          <Button icon onClick={this.navigateToPrevScan} className="prev">
            <Icon path={mdiMapMarkerLeft} size={1} />
          </Button>
        } />
        <Popup content="Later scan position" inverted position="top center" mouseEnterDelay={1000} trigger={
          <Button icon onClick={this.navigateToNextScan} className="next">
            <Icon path={mdiMapMarkerRight} size={1} />
          </Button>
        } />
        <DatePicker
          selected={this.state.reportDate}
          onChange={date => { this.setState({ reportDate: date }); }}
          popperPlacement="top"
          dateFormat="d MMM yyyy"
          calendarStartDay={1}
          customInput={<DatePickerButton />}
          showYearDropdown
          endDate={new Date()}
        />
      </div>
    );
  }

  protected navigateToPrevScan = (): void => {
    const index = this.config.currentScanLocationIndex - 1 >= 0
      ? this.config.currentScanLocationIndex - 1
      : this.config.numScanLocations - 1;

    eventBus.dispatchEvent(new Contilio360Event(Contilio360Event.REQUEST_SCAN_LOCATION, index));
  };

  protected navigateToNextScan = (): void => {
    const index = this.config.currentScanLocationIndex + 1 < this.config.numScanLocations
      ? this.config.currentScanLocationIndex + 1
      : 0;

    eventBus.dispatchEvent(new Contilio360Event(Contilio360Event.REQUEST_SCAN_LOCATION, index));
  };
}

const DatePickerButton = forwardRef(({ value, onClick }: any, ref: any) => (
  <Button onClick={onClick} ref={ref}>
    <Icon path={mdiCalendar} size={1} />
    {value}
  </Button>
));