import React, { ChangeEvent, FormEvent, PureComponent, ReactNode } from "react";
import { Button, Input } from "semantic-ui-react";
import './SignInView.scss';
import md5 from 'md5';

export class SignInView extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: false,
    };
  }

  public render(): ReactNode {
    return (
      <div className="SignInView">
        <form onSubmit={this.submitHandler}>
          <div className="logo"></div>
          <p>Welcome to Contilio 360, providing the complete, interactive, all round view of your project</p>
          <Input
            type="text"
            name="email"
            placeholder="Work Email"
            autoComplete="off"
            fluid
            error={this.state.error}
            onChange={this.changeHandler}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            autoComplete="off"
            fluid
            error={this.state.error}
            onChange={this.changeHandler}
          />
          <Button type="submit" color="blue" fluid>
            Log In
          </Button>
        </form>
      </div>
    );
  }

  protected submitHandler = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (md5(this.state.email + this.state.password + 'contilio360') === '43caebe414823483f0971e644cde966f') {
      this.props.onSignIn();
    } else {
      this.setState({ error: true });
    }
  };

  protected changeHandler = (event: ChangeEvent, data: any) => {
    this.setState({
      [data.name]: data.value,
      error: false,
    });
  };
}