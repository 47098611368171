import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ChangeEvent, PureComponent, ReactNode } from "react";
import { Button, Input, InputOnChangeData, Modal, Table, TextArea, TextAreaProps } from "semantic-ui-react";
import { INote } from "../../models/ConfigModel";
import './NoteViewer.scss';

/**
 * Simple note (Panellum info hotspot) viewer
 * @author  Neil Rackett
 */
export class NoteViewer extends PureComponent<any, any> {
  public render(): ReactNode {
    const note: INote = this.props.note;

    if (note) {
      return (
        <Modal
          className="NoteViewer"
          onClose={this.close}
          dimmer={{ blurring: true, inverted: true }}
          size='tiny'
          open
        >
          <Modal.Header>
            Field Note
            <span className="close" onClick={this.close}>
              <Icon path={mdiClose} size={1} />
            </span>
          </Modal.Header>
          <Modal.Content className="panorama-info-content">
            <Modal.Description>
              <Table basic="very" padded>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={5}>Title</Table.Cell>
                    <Table.Cell>
                      <Input
                        name="title"
                        defaultValue={note.title}
                        fluid
                        onChange={this.inputChangeHandler}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Note</Table.Cell>
                    <Table.Cell>
                      <TextArea
                        name="note"
                        defaultValue={note.note}
                        rows={3}
                        onChange={this.textAreaChangeHandler}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Date</Table.Cell>
                    <Table.Cell>
                      <Input
                        name="date"
                        type="date"
                        defaultValue={note.date}
                        fluid
                        onChange={this.inputChangeHandler}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>User</Table.Cell>
                    <Table.Cell>
                      <Input
                        name="user"
                        defaultValue={note.user}
                        fluid
                        onChange={this.inputChangeHandler}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {!!note.id && (
              <Button color="red" onClick={this.delete}>
                Delete
              </Button>
            )}
            <Button color="blue" onClick={this.save}>
              {note.id ? 'Update' : 'Save'}
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }

    return null;
  }

  protected inputChangeHandler = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    this.props.note[data.name] = data.value;
  };

  protected textAreaChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps): void => {
    this.props.note[data.name] = data.value;
  };

  protected save = (): void => {
    if (this.props.onSave) {
      this.props.onSave(this.props.note);
    }

    this.close();
  };

  protected delete = (): void => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      if (this.props.onDelete) {
        this.props.onDelete(this.props.note);
      }

      this.close();
    }
  };

  protected close = (): void => {
    if (this.props.onClose) {
      this.props.onClose(this.props.note);
    }
  };
}