import React, { PureComponent, ReactNode } from 'react';
import { eventBus } from './Contilio360Context';

export const Contilio360ReactContext = React.createContext({});

export class Contilio360Provider extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render(): ReactNode {
    const { children } = this.props;

    return (
      <Contilio360ReactContext.Provider value={eventBus} >
        {children}
      </Contilio360ReactContext.Provider>
    );
  }
}

export const Contilio360Consumer = Contilio360ReactContext.Consumer;
