import React, { PureComponent, ReactNode } from 'react';
import 'react-reflex/styles.css';
import './App.scss';
import { FooterView } from './view/FooterView';
import { HeaderView } from './view/HeaderView';
import { SignInView } from './view/SignInView';
import { ViewersView } from './view/ViewersView';

/**
 * Contilio 360
 *
 * This is a rapid prototype for the Contilio 360 web application, built using
 * an MVCS-ish approach, though without much separation between V and C, so
 * that will probably need to be sorted when we move to the next stage of
 * development.
 *
 * @author  Neil Rackett
 */
export class App extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSignedIn: localStorage.getItem('isSignedIn') === 'true',
    };
  }

  public render(): ReactNode {
    return (
      <div className="App">
        {
          this.state.isSignedIn ? (
            <>
              <HeaderView />
              <ViewersView />
              <FooterView />
            </>
          ) : (
            <SignInView onSignIn={this.signInHandler} />
          )
        }
      </div>
    );
  }

  protected signInHandler = (): void => {
    localStorage.setItem('isSignedIn', 'true');
    this.setState({ isSignedIn: true });
  };
}
